import React, { Component,Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Detail = lazy(() => import('./detail/Detail'));
const Report = lazy(() => import('./report/Report'));

const Mdi = lazy(() => import('./icons/Mdi'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register = lazy(() => import('./user-pages/Register'));
const VerifyAccount = lazy(() => import('./user-pages/VerifyAccount'));
const Forgot = lazy(() => import('./user-pages/Forgot'));
const Settings = lazy(() => import('./user-pages/Settings'));
const Allometry = lazy(() => import('./allometry/Allometry'));

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/report/:id" element={<Report />} />

          <Route path="/icons/mdi" element={<Mdi />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify" element={<VerifyAccount />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/allometry" element={<Allometry />} />

          <Route path="/error-pages/error-404" element={<Error404 />} />
          <Route path="/error-pages/error-500" element={<Error500 />} />

          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="*" element={<Navigate to="/error-pages/error-404" />} />
        </Routes>
      </Suspense>
    );
  }
}

export default AppRoutes;