import React, { Component } from 'react';
import Logo_of_CAL_FIRE from '../../assets/images/samples/Logo_of_CAL_FIRE.svg.png';
import calpolyLogo from '../../assets/images/samples/calpoly-logo.png';
import US_Forest_Service from '../../assets/images/samples/US_Forest_Service.png';
import './Footer.css'

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between py-2 w-100 align-items-center">
            <span className="text-center text-sm-left d-block d-sm-inline-block">
              Copyright ©<a href="https://ufei.calpoly.edu/" target="_blank" rel="noopener noreferrer" className="footer-link-text">
                UFEI
              </a> 2024
            </span>
            <a href="https://www.fire.ca.gov/" target="_blank" rel="noopener noreferrer">
              <img src={Logo_of_CAL_FIRE} alt="CAL FIRE logo" className="footer-logo footer-small-logo" />
            </a>
            <a href="https://www.calpoly.edu/" target="_blank" rel="noopener noreferrer">
              <img src={calpolyLogo} alt="Cal Poly logo" className="footer-logo footer-big-logo" />
            </a>
            <a href="https://www.fs.usda.gov/" target="_blank" rel="noopener noreferrer">
              <img src={US_Forest_Service} alt="US Forest Service logo" className="footer-logo footer-small-logo" />
            </a>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
              Trees from <a href="https://www.selectree.calpoly.edu/" target="_blank" rel="noopener noreferrer" className='footer-link-text'>
                selectree.calpoly.edu
              </a>
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;