import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import RufaNavbar from './shared/Navbar';
import Footer from './shared/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const location = useLocation();

  const onRouteChanged = useCallback(() => {
    console.log("route changed");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/Mdi', '/settings', '/login', '/register', '/forgot', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    const isFullPage = fullPageLayoutRoutes.includes(location.pathname);
    setIsFullPageLayout(isFullPage);
    const pageBodyWrapper = document.querySelector('.page-body-wrapper');
    if (isFullPage) {
      pageBodyWrapper.classList.add('full-page-wrapper');
    } else {
      pageBodyWrapper.classList.remove('full-page-wrapper');
    }
  }, [location]);

  useEffect(() => {
    onRouteChanged();
  }, [onRouteChanged]);

  let navbarComponent = !isFullPageLayout ? <RufaNavbar /> : '';
  let footerComponent = !isFullPageLayout ? <Footer /> : '';

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper" fluid flex>
        {navbarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
            <ToastContainer />
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
};

export default (App);
