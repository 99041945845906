import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import api from "../Api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const userLogout = () => {
  localStorage.clear();
  window.location.reload();
  console.log("User logged out");
};

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
    };

    const userAccStore = (data) => {
      localStorage.setItem("user", JSON.stringify(data.response));
      localStorage.setItem("firstname", data.response.first_name);
      localStorage.setItem("lastname", data.response.last_name);
      localStorage.setItem("role", data.response.role_name);
      localStorage.setItem("verified", data.response.verified);
      localStorage.setItem("token", data.token);
      localStorage.setItem("loggedIn", true);
      window.location.href = window.location.origin;
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    try {
      const response = await api.post('/auth/login', data, config);
      toast.success('Logged in! Redirecting you now...', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log('Login successful. Response data:', response.data);
      userAccStore(response.data);
      const { state } = location;
      const lastVisited = state?.from || '/';
      navigate(lastVisited);
    } catch (error) {
      console.error('Login failed. Error:', error);
      toast.error('Incorrect email or password', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-5 mx-auto auth">
            <div className="card text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src="https://selectree.calpoly.edu/images/logos/UFEI.png" alt="logo" />
              </div>
              <h4 className='text-black'>Hello! Let's get started</h4>
              <h6 className="font-weight-light text-black">Sign in to continue.</h6>
              <Form className="pt-3">
                <Form.Group className="d-flex search-field">
                  <Form.Control type="email" placeholder="Username" size="lg" className="h-auto text-black mb-3" onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className="d-flex search-field">
                  <Form.Control type="password" placeholder="Password" size="lg" className="h-auto text-black" onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <div className="mt-3">
                  <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleSubmit}>
                    Sign In
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      <i className="input-helper"></i>
                      Keep me signed in
                    </label>
                  </div>
                  <Link to="/forgot" className="font-weight-light text-primary">Forgot password</Link>
                </div>
                <div className="text-center mt-4 font-weight-light text-black">
                  Don't have an account? <Link to="/register" className="text-primary">Create account</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
