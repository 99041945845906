import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

function CoreRender() {
  useEffect(() => {
    console.log("rendered");
  });

  return <BrowserRouter basename="/">
       <App />
     </BrowserRouter>;
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<CoreRender />);

serviceWorker.unregister();