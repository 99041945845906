import React, { Component } from 'react';
import { Nav, NavDropdown, Container, Navbar, Image, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userLogout } from '../user-pages/Login';
import ufeiLogo from '../../assets/images/ufei_logo.svg';
import './Navbar.css';
import { Search } from '../search/Search'


class RufaNavbar extends Component {
  render() {
    return (
      <Container id="rufa-navbar-container" className="rufa-navbar" fluid flex>
        <Navbar collapseOnSelect expand="lg" className="bg-light navbar-light border-0">
          <Navbar.Brand href="https://ufei.calpoly.edu" className="col-4 col-lg-2 mr-auto d-inline-block align-top">
            <Image
              src={ufeiLogo}
              className="rufa-navbar-logo"
              fluid
              style={{ width: "150px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
            <Nav className="ml-auto d-lg-flex justify-content-between align-items-center " basic-navbar-nav>
              <NavDropdown
                title={<span className="rufa-navbar-dropdown-title-text">RESOURCES</span>}
                id="collasible-nav-dropdown-1"
                drop={"down"}
                className="rufa-navbar-dropdown-title-container-resources"
              >
                <NavDropdown.Item
                  href="https://selectree.calpoly.edu/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  SELECTREE
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://californiabigtrees.calpoly.edu/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  CALIFORNIA BIG TREES
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://urbantreekey.calpoly.edu/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  URBAN TREE KEY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://datastudio.google.com/reporting/880d448d-de26-48d3-b563-0c6317e456e4/page/jWHKB"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  URBAN TREE INVENTORY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://pacificislandstreekey.calpoly.edu/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  PACIFIC ISLANDS TREE KEY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://jventu09.users.earthengine.app/view/urban-tree-detector"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  URBAN TREE DETECTOR
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://calpoly.maps.arcgis.com/apps/webappviewer/index.html?id=3c4233d842a64e41ac9cf3713848a481"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  CA NATIVE TREES
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://selectree.calpoly.edu/search-lists"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  TREE LISTS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://rufa.calpoly.edu/allometry"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  TREE ALLOMETRY
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={<span className="rufa-navbar-dropdown-title-text">DETAILS</span>}
                id="collasible-nav-dropdown-2"
                drop={"down"}
                className="rufa-navbar-dropdown-title-container-details"
              >
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/about-ufei/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  ABOUT UFEI
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/ufei-publications/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  UFEI PUBLICATIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/references/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  CITATIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/urban-wood/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  URBAN WOOD INFORMATION
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://ufei.calpoly.edu/pitch-canker-task-force/"
                  target="_blank"
                  className="dropdown-hover-highlight"
                >
                  PITCH CANKER TASK FORCE
                </NavDropdown.Item>
              </NavDropdown>
              <div className="mr-0 rufa-navbar-login-button">
                <ul className="navbar-nav-right rufa-navbar-login-ul">
                  {localStorage.getItem("loggedIn") === "true" && (
                    <div className="">
                      <Dropdown alignright="true" className="nav-item">
                        <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                          <div className="navbar-profile">
                            <div className="mdi mdi-account-circle justify-items-center align-content-center"
                              style={{ height: '1em', width: '10px', fontSize: '20px', color: "black" }}
                            >
                              <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                            </div>
                            {/* <p className="mb-0 d-none d-sm-block navbar-profile-name" style={{ color: "black" }}>{localStorage.getItem("firstname") + ' ' + localStorage.getItem("lastname")}</p> */}
                          </div>
                        </Dropdown.Toggle>
                        <div className='login-dropdown-menu'>
                          <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu ">
                            <h6 className="p-3 mb-0">Profile</h6>
                            <Dropdown.Divider />
                            <Dropdown alignright="true" className="nav-item d-none d-lg-block">
                              <Dropdown.Toggle className="nav-link btn btn-success create-new-button no-caret">
                                + Upload New Trees
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu">
                                <h6 className="p-3 mb-0">Trees</h6>
                                <Dropdown.Divider />
                                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                                  <div className="preview-thumbnail">
                                    <div className="preview-icon bg-secondary rounded">
                                      <i className="mdi mdi-file-outline text-primary"></i>
                                    </div>
                                  </div>
                                  <div className="preview-item-content">
                                    <p className="preview-subject">Tree Data</p>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                                  <div className="preview-thumbnail">
                                    <div className="preview-icon bg-secondary rounded">
                                      <i className="mdi mdi-web text-info"></i>
                                    </div>
                                  </div>
                                  <div className="preview-item-content">
                                    <p className="preview-subject">City Data</p>
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown.Divider />
                            <Dropdown.Item href="settings" className="preview-item">
                              <div className="preview-thumbnail">
                                <div className="preview-icon bg-secondary rounded">
                                  <i className="mdi mdi-settings text-success"></i>
                                </div>
                              </div>
                              <div className="preview-item-content">
                                <p className="preview-subject mb-1">Settings</p>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="logout" onClick={userLogout} className="preview-item">
                              <div className="preview-thumbnail">
                                <div className="preview-icon bg-secondary rounded">
                                  <i className="mdi mdi-logout text-danger"></i>
                                </div>
                              </div>
                              <div className="preview-item-content">
                                <p className="preview-subject mb-1">Log Out</p>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </div>
                      </Dropdown>
                    </div>
                  )}
                  {localStorage.getItem("loggedIn") !== "true" && (
                    <Link
                      to="/login"
                      alignright="true"
                      as="li"
                      type="button"
                      className='nav-item btn'
                      style={{
                        backgroundColor: '#2D2D2D', borderBlockColor: "#2D2D2D", margin: "2.4px 24px",
                        padding: "0.45rem", paddingLeft: "1rem", paddingRight: "1rem", fontFamily: 'Arial'
                      }}>
                      <Row style={{ paddingRight: "0.9rem", paddingLeft: "0.9rem" }}>
                        <span><i className="mdi mdi-login text-white" style={{ fontSize: '1em' }}></i></span>
                        <span style={{ color: "white" }}>LOGIN</span>
                      </Row>
                    </Link>
                  )}
                </ul>
              </div>
            </Nav>
          </Navbar.Collapse>
          <div className="d-lg-none container-fluid rufa-navbar-search">
            <Search />
          </div>
        </Navbar>
      </Container>
    );
  }
}

export default RufaNavbar;

