import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import api from "../Api";
import "./Search.css";

const HOST = window.location.hostname === "localhost" ? "http://localhost:3000" : process.env.PUBLIC_URL;
const CITY_DETAIL_ENDPOINT = HOST + "/detail/";

export function Search() {
  const [input, setInput] = useState("");
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const [activeSearch, setActiveSearch] = useState(false);

  useEffect(() => {
    if (isSearching) {
      const timer = setTimeout(() => {
        searchCity(input);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [input, isSearching]);

  useEffect(() => {
    const searchListElement = document.getElementById("searchList");
    if (searchListElement) {
      if (results.length > 0 && results.length != null) {
        if (!activeSearch) {
          setTimeout(function () {
            searchListElement.style.display = "none";
          }, 250)
        } else if (activeSearch) {
          searchListElement.style.display = "block";
        }
      }
    }
  }, [activeSearch, results.length]);

  useEffect(() => { // Scroll to focused item
    if (focusedIndex >= 0) {
      const focusedElement = document.querySelector(`.list-item.focused`);
      if (focusedElement) {
        focusedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [focusedIndex]);

  const handleChange = (event) => {
    setInput(event.target.value);
    setIsSearching(true);
    setFocusedIndex(-1);
  };

  const searchCity = async (searchString) => {
    const searchResults = [];
    if (!searchString || searchString.trim() === '') {
      setResults([]);
      return;
    }
    try {
      const citySearchResponse = await api.get(`/rufa/city/search`, {
        params: {
          searchString: searchString
        }
      });
      for (const i in citySearchResponse.data) {
        searchResults.push(citySearchResponse.data[i]);
      }
      setResults(searchResults);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (results.length > 0 && (event.key === "ArrowDown" || event.key === "ArrowUp" || event.key === "Enter")) {
      if (event.key === "ArrowDown" && focusedIndex + 1 <= Math.min(results.length - 1)) {
        event.preventDefault(); // Prevent page scrolling
        setFocusedIndex(focusedIndex => focusedIndex + 1);
        setInput(results[focusedIndex + 1].city_name);
      } else if (event.key === "ArrowUp" && focusedIndex > 0) {
        event.preventDefault(); // Prevent page scrolling
        setFocusedIndex(focusedIndex => Math.max(focusedIndex - 1, -1));
        setInput(results[Math.max(focusedIndex - 1, -1)].city_name);
      } else if (event.key === "Enter" && focusedIndex >= 0) {
        event.preventDefault();
        setInput(results[focusedIndex].city_name);
        window.location.href = `${CITY_DETAIL_ENDPOINT}${results[focusedIndex].city_id}`;
      }
    }
  };

  return (
    <div fluid className='search-container'>
      <Form onSubmit={handleSubmit} >
        <input
          ref={searchInputRef}
          type="search"
          name="citySearchField"
          className='search-input'
          placeholder="Enter a city name..."
          value={input}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          spellCheck="false"
          autoComplete="off"
          onBlur={() => setActiveSearch(false)}
          onFocus={() => setActiveSearch(true)}
        />
      </Form>
      <div className='search-box-results'>
        {input && results.length === 0 && (
          <ul className="search-results search-no-results">
            <li className="p-2">No results</li>
          </ul>
        )}
        {input && (
          <ul className="search-results" id="searchList">
            {results.map((result, index) => (
              <li key={index} className={`list-item ${index === focusedIndex ? 'focused' : ''}`} onClick={() => setInput(result.city_name)} style={{ overflowX: "auto", overflowY: "auto" }}>
                <a href={`${CITY_DETAIL_ENDPOINT}${result.city_id}`}>{result.city_name}</a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Search;